import React, { FunctionComponent } from "react";

export interface AppStateLoading {
  state: "LOADING";
}

interface LoadingProps {
  state: AppStateLoading;
}

const ICON_SIZE = 256;

export const Loading: FunctionComponent<LoadingProps> = () => {
  return (
    <div>
      <div className="px-4 py-5 sm:p-6 text-center text-2xl">Identity Card</div>
      <div className="px-4 py-5 sm:p-6 flex place-content-center animate-spin">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          style={{ width: ICON_SIZE }}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
          />
        </svg>
      </div>
    </div>
  );
};
